import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const LocalAuthorities = () => {
    return (
        <Layout>
            <Seo
      title="Autorités Locales - BIKESAFE"
      description="Bike Safe a travaillé avec de nombreuses autorités locales pour installer des systèmes de rangement et de parkings pour vélos en ville, que ce soient dans les centres villes, les centres commerciaux, les parkings de bureaux administratifs, les gares routières et ferroviaires ainsi que les établissements scolaires."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Autorités Locales
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Bike Safe a travaillé avec de nombreuses autorités locales pour installer des systèmes de rangement et de parkings pour vélos en ville, que ce soient dans les centres villes, les centres commerciaux, les parkings de bureaux administratifs, les gares routières et ferroviaires ainsi que les établissements scolaires.
                        </p>
                        <p className="flow-text">
                        La présence de parkings sécurisés pour vélos incite les gens à circuler à vélo en ville, ce qui réduit la circulation et la pollution. Faire du vélo est également bon pour la santé.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/localauthoritypic.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">« Votre service est excellent ! Je recommande vos services sans hésitation ! »
                                <strong> Gina Harkell, agent chargé du programme cycliste, district londonien de Waltham Forrest</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default LocalAuthorities;